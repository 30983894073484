import React, { useEffect, useState } from 'react';
import { Title, Text, Space, Emoji, AllPageSpinner, CenterContainer } from '../../components';
import { PageProps } from '.';
import styled from 'styled-components';
import averageDailyUsageByAge from '../../reports/app-usage/average-daily-usage-by-age';

const Arrow = styled.img`
    align-self: center;
    margin-right: 100px;

    
`

const StepsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

const Steps = styled.div`
    text-align: left;
    margin-left: 23px;
`

const StepTitle = styled(Text)`
    color: #000000;
    font-weight: 700;
    font-size: 12px;
`

const DidYouKnow = styled.div`
    display: flex;
    flex-direction: row;
    textAlign: left;
    background: rgba(227, 230, 242, 0.3);
    padding: 7px;

    @media (min-device-width: 1024px){
        margin-right: 80px;
        margin-left: 80px;
    }
`;

export default function GoToPlayStore({ dispatch, state }: PageProps) {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const { kidName, kidGender, kidAge, kidPhoneType } = state;

        fetch("https://us-central1-carent-bd07d.cloudfunctions.net/onboarding",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    'Authorization': 'Bearer ' + state.token,
                },
                body: JSON.stringify({ kidName, kidGender, kidAge, kidPhoneType })
            }
        ).then(() => setIsLoading(false)).catch(x => console.warn("failed to update state", x))
    }, [state])

    return <>
        {isLoading && <AllPageSpinner />}
        <Title>You’re a few minutes away from getting your first report…</Title>
        <Arrow src={require("./assets/ic_arrow.svg")} alt="" />
        <StepsContainer>
            <img src={require('./assets/ic_glimpse.svg')} alt="glimpse" />
            <Steps style={{ textAlign: "left", marginLeft: 23 }}>
                <StepTitle>Step 1</StepTitle>
                <br />
                <Text>Go to {state.kidName}'s phone</Text>
                <br /><br />
                <StepTitle>Step 2</StepTitle>
                <br />
                <Text>Download<br /><b>Glimpse - Parental App</b><br /> on Google Play</Text>
            </Steps>
        </StepsContainer>
        <Space style={{ height: 70 }} />
        <CenterContainer style={{ width: "90%" }}>
            <DidYouKnow>
                <Emoji symbol="☝️" />
                <Text style={{ marginLeft: 11, fontSize: 14 }}>A <b>{state.kidAge}</b> year old, in the U.S, spends an average of <b>{averageDailyUsageByAge[state.kidAge as 9]}</b> daily hrs on their mobile. Download to see where {state.kidName} stands</Text>
            </DidYouKnow>
        </CenterContainer>
    </>
}