import React, { useState, useEffect } from 'react';
import {
    Title,
    Form,
    FormGroup,
    FormInput,
    FormLabel,
    Feedback,
    Button,
    Space,
    Emoji,
    AllPageSpinner,
} from '../../components';
import sendActivationEmail from './actions/sendActivationMail';
import { PageProps } from '.';

export default function NameAndEmail({ navigate, dispatch, state }: PageProps) {
    const [name, setName] = useState(() => state.name || '');
    const [email, setEmail] = useState(() => state.email || '');
    const [isLoading, setIsLoading] = useState(false);

    const uppercasedName = name.replace(/^\w/, c => c.toUpperCase());
    useEffect(() => {
        dispatch({
            type: 'parent-name-and-email-posted',
            name: uppercasedName,
            email,
        });
    }, [dispatch, uppercasedName, email])

    return (
        <>
            {isLoading && <AllPageSpinner />}
            <Title>
                Nice to meet you <Emoji symbol="👋" /> we’re Glimpse, and you are?
            </Title>
            <Form
                style={{ flex: 1 }}
                onSubmit={e => {
                    e.preventDefault();
                    setIsLoading(true);
                    sendActivationEmail({ email, name: uppercasedName })
                        .then(() => {
                            navigate("email-sent")
                            setIsLoading(false);
                        })
                        .catch(() => setIsLoading(false));
                }}
            >
                <FormGroup>
                    <FormInput
                        value={name}
                        minLength={2}
                        pattern="[A-Za-z ]+"
                        onChange={(e: any) => setName(e.target.value)}
                        required
                    />
                    <FormLabel>First Name</FormLabel>
                    <Feedback>Enter a name, anything will do</Feedback>
                </FormGroup>

                <FormGroup>
                    <FormInput value={email} onChange={(e: any) => setEmail(e.target.value)} type="email" required />
                    <FormLabel>Email</FormLabel>
                    <Feedback>Please provide a valid email</Feedback>
                </FormGroup>
                <Space />
                <Button disabled={!name || !email} type="submit">
                    Next
                </Button>
            </Form>
        </>
    );
}
