import styled, { CSSProperties, keyframes } from 'styled-components';
import React, { FormEvent, useState, FormEventHandler, useRef } from 'react';

export const Text = styled.span`
    font-family: museo-sans-rounded;
    font-weight: 300;
    color: #002952;
`;

export const Title = styled(Text)`
    font-size: 32px;
    line-height: 108.47%;
    font-weight: 700;
    margin-bottom: 42px;
    margin-top: 32px;

    @media (min-device-width: 1024px){
        margin-bottom: 72px;
        margin-top: 64px;
        margin-left: 64px;
        margin-right: 64px;
    }
`;

export const FormGroup = styled.div`
    position: relative;
    textAlign: left;
    height: 68px;
    margin-bottom: 10px;

    @media (min-device-width: 1024px){
        margin-bottom: 24px;
    }
`;

export const FormLabel = styled.label`
    font-family: 'museo-sans-rounded';    
    position: absolute;
    top: 5px;
    left: 20px;
    color: rgba(155, 161, 184, 0.61);
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    transform: translate(0, 12px) scale(1);
    font-size: 16px;
    pointer-events: none;
`

export const Feedback = styled.p`
    font-family: 'museo-sans-rounded';
    display: none;
    color: #FF5070;
    font-size: 12px;
    line-height: 111.5%;
    text-align: left;
    margin: 4px;
    margin-left: 22px;
`

export const FormInputComponent = styled.input`
    font-family: 'museo-sans-rounded';    

    &:invalid ~ ${Feedback} {
        display: block;
    }

    &:focus {
        outline: none;
    }

    background: #FFFFFF;
    border: 1px solid rgba(107, 112, 140, 0.14);
    box-sizing: border-box;
    box-shadow: 0px 5px 11px rgba(155, 161, 184, 0.09);
    border-radius: 30px;
    width: 291px;
    height: 51px;
    padding-left: 20px;
    padding-top: 15px;
    font-size: 16px;
    color: #002952;
    
    ${ ({ value }) =>
        `&${value ? "" : ":focus"} ~ ${FormLabel} {
            transform: translate(0, 1.5px) scale(0.8);
            transform-origin: top left;
        }`
    }    
`;

export function FormInput(props: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) {
    const ref = useRef(null)
    return <FormInputComponent autoComplete="off" {...props} ref={ref} onFocus={(e: any) => {
        e.target.scrollIntoView(true)
        return props.onFocus && props.onFocus(e)
    }} />
}

const FormComponent = styled.form`
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &.no-validate ${Feedback} {
        display: none !important;
    }
`

export const ButtonText = styled.button`
    font-family: museo-sans-rounded;
    font-weight: 300;
    color: #002952;
`;

export const Button = styled(ButtonText)`
    text-align: center;
    background: #4d6af9;
    border-radius: 33px;

    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #fff;
    padding: 15px;
    display: inline-block;
    min-width: 161px;
    width: fit-content;
    align-self: center;
    border-width: 0px;
    height: 51px;

    &:hover {
        background-color: #4150FF;
    }
    &:disabled {
        background-color: #E3E6F2;
    }

    &:focus {
        outline: none;
    }
`;

export function Form({ style, children, onSubmit }: { style?: CSSProperties, children: any, onSubmit: FormEventHandler<Element> }) {
    const [shouldValidate, setShouldValidate] = useState(false);

    const onSubmitHandler = (event: FormEvent) => {
        const form = event.currentTarget as any;

        if (form.checkValidity() === false) {
            setShouldValidate(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            event.stopPropagation();
            return onSubmit(event)
        }
    };

    return (
        <FormComponent style={style} onSubmit={onSubmitHandler} noValidate className={shouldValidate ? "validate" : "no-validate"} >
            {children}
        </FormComponent>
    );
};

export const Space = styled.div`
    min-height: 22px;

    @media (min-device-width: 1024px){
        flex: 1;
    }
`

const SelectionContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const OptionContainer = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    width: 100px;
    height: 100px;
    border: ${({ selected }) => selected ? "2px" : "0px"} solid #4D6AF9;
    box-shadow: 0px 2px ${({ selected }) => selected ? "25px rgba(107, 112, 140, 0.2)" : "15px rgba(107, 112, 140, 0.14)"};
    box-sizing: border-box;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 16px;
`

export const SelectionText = styled(Text) <{ big?: boolean }>`
    font-size: ${(props) => props.big ? "30px" : "16px"};
    font-weight: 500;
    color: #002952;
    line-height: 20px;
`

export function SingleSelection({ options, onSelect, initialValue }: { options: { [value: string]: any }, onSelect: (value: string) => void, initialValue?: string }) {
    const [selected, select] = useState(() => initialValue);
    return <SelectionContainer>
        {Object.entries(options).map(([value, RenderComponent]) =>
            <OptionContainer key={value} selected={value === selected} onClick={() => { select(value); onSelect(value); }}>
                {RenderComponent}
            </OptionContainer>)}
    </SelectionContainer>
}

export function Emoji({ label, symbol, size }: { label?: string, symbol: string, size?: number }) {
    return <span
        className="emoji"
        role="img"
        aria-label={label || ""}
        aria-hidden={!label}
        style={{ fontSize: size || "auto" }}
    >
        {symbol}
    </span>
}


const Arrow = ({ className }: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="48" viewBox="0 0 54 48" className={className}>
        <path
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="3"
            d="M2.47178 23.701C0.843009 20.2407 3.16775 0.249289 13.6074 4.50581C17.0871 6.89805 21.4742 9.91131 19.083 14.8417C16.4644 20.2407 11.3311 15.2856 11.3311 10.9657C11.3311 3.46366 20.0424 1.55271 25.7274 1.55271C37.9877 1.55271 49.28 9.86644 51.7516 22.2244C54.0351 33.6419 48.0203 43.9765 36.2479 45.7877C25.0938 47.5037 20.159 44.3078 12.9307 36.8669C10.6161 34.4843 8.53128 29.6096 7.45514 34.7751C7.23066 35.8526 6.26504 44.8072 6.83991 41.358C7.23072 39.0131 7.32061 35.87 8.00884 33.6677C8.59814 31.7819 19.8124 34.3159 22.4052 33.6677"
        />
    </svg>
);

const dash = keyframes`
  0% {
    stroke-dashoffset: 410;
  }
  75% {
    stroke-dashoffset: 0;

  }
  100% {
    stroke-dashoffset: 0;
  }
`;

export const Spinner = styled(Arrow) <{ color?: string }>`
    stroke: #6C84FD;
    stroke-dasharray: 205;
    stroke-dashoffset: 410;
    animation: ${dash} 1.3s ease infinite;
    transform: rotate(95deg);
    z-index: 999;
`;

export const PageSpinner = styled(Spinner)`
    margin: 10% auto 0 auto;
`;

const AllPage = styled.div`
    position:fixed;
    z-index: 100;
    padding:0;
    margin:0;

    top:0;
    left:0;

    width: 100%;
    height: 100%;
    background:rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(20px);
`

export const AllPageSpinner = () => <AllPage><PageSpinner /></AllPage>

export const CenterContainer = styled.div`
    align-self: center;
`;