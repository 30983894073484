const host = 'https://us-central1-carent-bd07d.cloudfunctions.net';

export default async function sendActivationEmail({email, name}: {email: string; name: string}) {
    return await fetch(`${host}/auth/pwdless/request-signin`, {
        method: 'POST',
        body: JSON.stringify({
            action: 'signup',
            email,
            name,
        }),
        headers: {
            'content-type': 'application/json',
        },
    });
}
