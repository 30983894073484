import NameAndEmail from "./name-and-email";
import EmailSent from "./email-sent";
import KidGender from "./kid-gender";
import KidAge from "./kid-age";
import KidName from "./kid-name";
import GoToPlayStore from "./go-to-play-store";
import KidPhoneType from "./kid-phone-type";

const pages: { [page: string]: any } = {
    "parent-name-and-email": NameAndEmail,
    "email-sent": EmailSent,
    "kid-gender": KidGender,
    "kid-age": KidAge,
    "kid-name": KidName,
    "kid-phone-type": KidPhoneType,
    "go-to-playstore": GoToPlayStore
}

export default pages;

export type PageProps = { navigate: (page: string) => void, dispatch: React.Dispatch<any>, state: State };

export type State = {
    name?: string,
    token?: string,
    email?: string,
    kidGender?: "boy" | "girl" | "private",
    kidAge?: number,
    kidName?: string,
    kidPhoneType?: "android" | "ios"
};