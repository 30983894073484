import React, {useState, CSSProperties} from 'react';

export default function TextCollapse({
    text,
    collapseText,
    collapseTextStyle,
    previewLength,
}: {
    text: string;
    collapseText: string;
    collapseTextStyle: CSSProperties;
    previewLength: number;
}) {
    const [collapse, setCollapse] = useState(text.length > previewLength + 3);
    return (
        <span>
            {collapse ? `${text.substring(0, previewLength)}...` : text}
            {collapse && (
                <span style={collapseTextStyle} onClick={() => setCollapse(false)}>
                    {' '}
                    {collapseText}
                </span>
            )}
        </span>
    );
}
