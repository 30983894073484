import React from 'react';
import { useAppUsage } from './use-app-usage';
import { gameCategoriesInsights, InsightDescription, categoriesInsights } from './categories-insights';
import observationIcon from './assets/observation.svg';
import { categoryAssets } from './assets/categories';
import { AppUsageData } from './types';
import {
    Button, Text
} from '../../components';
import {
    Section,
    SectionCard,
    TotalUsageStats,
    InfoText,
    Separator,
    SectionTitle,
    TotalUsageStatsNumber,
    Circle,
    Icon,
    SectionContent,
    SectionContentTitle,
    LearnMoreLink,
    Container,
    Spinner,
    Title,
    Subtitle,
    SubtitleBlue,
    TotalUsageStatsMeasure,
} from './components';
import TakeItToYourDailyLifeBg from './assets/take-it-to-your-daily-life-bg.png';
import boyIcon from './assets/boy.svg';
import girlIcon from './assets/girl.svg';
import genericGenderIcon from './assets/generic-gender.svg';
import kidsIcon from './assets/kids.svg';
import averageDailyUsageByAge from './average-daily-usage-by-age';
import glingLeft from './assets/gling-left.svg';
import glingRight from './assets/gling-right.svg';
import favAppGlings from './assets/fav-app-glings.svg';
import logo from './assets/logo.svg';
import TextCollapse from './text-collapse';

const decimalHoursFormat = (hours: number) => {
    var hrs = Math.floor(Math.abs(hours));
    var min = Math.floor((Math.abs(hours) * 60) % 60);
    return `${hours < 0 ? '-' : ''}${hrs}h ${min}m`;
};

function MobileScreenTime({ appUsage }: { appUsage: AppUsageData }) {
    return (
        <Section>
            <SectionTitle>Phone usage (past week)</SectionTitle>
            <SectionCard>
                <TotalUsageStats>
                    <InfoText>{appUsage.kidName}</InfoText>
                    <Icon
                        size={56}
                        src={
                            appUsage.kidGender === 'boy'
                                ? boyIcon
                                : appUsage.kidGender === 'girl'
                                    ? girlIcon
                                    : genericGenderIcon
                        }
                    />
                    <TotalUsageStatsNumber>
                        {decimalHoursFormat(appUsage.totalUsageTime / appUsage.periodInDays)}{' '}
                        <TotalUsageStatsMeasure> per day</TotalUsageStatsMeasure>
                    </TotalUsageStatsNumber>
                </TotalUsageStats>
                <Separator />
                <TotalUsageStats>
                    <TotalUsageStats>
                        <InfoText>Other ({appUsage.kidAge}-year olds)</InfoText>
                        <Icon style={{ width: 91 }} size={56} src={kidsIcon} />
                        <TotalUsageStatsNumber>
                            {averageDailyUsageByAge[appUsage.kidAge]}{' '}
                            <TotalUsageStatsMeasure> per day</TotalUsageStatsMeasure>
                        </TotalUsageStatsNumber>
                    </TotalUsageStats>
                </TotalUsageStats>
            </SectionCard>
        </Section>
    );
}

function TopInterestCategories({ appUsage }: { appUsage: AppUsageData }) {
    return (
        <Section>
            <SectionTitle>{appUsage.kidName}'s top interest categories </SectionTitle>
            <SectionCard style={{ height: 184 }}>
                {appUsage.topCategories.category1 && (
                    <Circle
                        color={categoryAssets[appUsage.topCategories.category1].color}
                        size="large"
                        icon={categoryAssets[appUsage.topCategories.category1].icon}
                        text={appUsage.topCategories.category1}
                    />
                )}
                {appUsage.topCategories.category2 && (
                    <Circle
                        color={categoryAssets[appUsage.topCategories.category2].color}
                        size="medium"
                        icon={categoryAssets[appUsage.topCategories.category2].icon}
                        text={appUsage.topCategories.category2}
                    />
                )}
                {appUsage.topCategories.category3 && (
                    <Circle
                        color={categoryAssets[appUsage.topCategories.category3].color}
                        size="small"
                        icon={categoryAssets[appUsage.topCategories.category3].icon}
                        text={appUsage.topCategories.category3}
                    />
                )}
            </SectionCard>
        </Section>
    );
}

function TopGameCategories({ appUsage }: { appUsage: AppUsageData }) {
    return (
        <Section>
            <SectionTitle>{appUsage.kidName}'s top game categories </SectionTitle>
            <SectionCard style={{ height: 184 }}>
                {appUsage.topGamingCategories.category1 && (
                    <Circle
                        color={categoryAssets['Gaming'].color}
                        size="large"
                        text={appUsage.topGamingCategories.category1}
                    />
                )}
                {appUsage.topGamingCategories.category2 && (
                    <Circle
                        color="rgba(73, 224, 244, 0.6)"
                        size="medium"
                        text={appUsage.topGamingCategories.category2}
                    />
                )}
                {appUsage.topGamingCategories.category3 && (
                    <Circle
                        color="rgba(73, 224, 244, 0.3)"
                        size="small"
                        text={appUsage.topGamingCategories.category3}
                    />
                )}
            </SectionCard>
        </Section>
    );
}

function LearnBasedOnApps({
    appUsage,
    insight,
    type,
    title,
}: {
    appUsage: AppUsageData;
    insight: InsightDescription;
    type: string;
    title: boolean;
}) {
    return (
        <Section>
            {title && <SectionTitle>What can you learn about {appUsage.kidName}?</SectionTitle>}
            <SectionCard>
                <Icon style={{ marginRight: 16, display: 'inline-flex' }} size={68} src={observationIcon} />
                <SectionContent>
                    <div style={{ marginBottom: 9 }}>
                        <InfoText>Based on {type}</InfoText>
                    </div>
                    <SectionContentTitle>
                        {insight.observationInsightTitle.replace('{kid_name}', appUsage.kidName)}
                    </SectionContentTitle>
                    <br />
                    <TextCollapse
                        collapseText="Read&nbsp;more"
                        collapseTextStyle={{
                            fontWeight: 500,
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}
                        previewLength={70}
                        text={`${insight.observationInsightDescription.replace('{kid_name}', appUsage.kidName)} `}
                    />
                </SectionContent>
            </SectionCard>
        </Section>
    );
}

function TakeItToYourDailyLife({ appUsage, insight }: { appUsage: AppUsageData; insight: InsightDescription }) {
    const content = insight.actionableInsight.replace('{kid_name}', appUsage.kidName);
    return (
        <Section>
            <SectionCard
                style={{
                    background: `rgba(1, 41, 82, 0.6) url(${TakeItToYourDailyLifeBg})`,
                    backgroundBlendMode: 'darken',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '60% 48%',
                    textAlign: 'center',
                }}
            >
                <SectionContent style={{ color: '#fff', fontWeight: 700, fontSize: content.length > 127 ? 16 : 18 }}>
                    <SectionContentTitle
                        style={{
                            color: '#fff',
                            marginBottom: 21,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <img src={glingLeft} alt="gling" />
                        <span style={{ margin: 1 }}>Use it to your daily life</span>
                        <img src={glingRight} alt="gling" />
                    </SectionContentTitle>
                    {content}
                    <br />
                    <a href={insight.buttonLink} target="_blank" rel="noopener noreferrer">
                        <Button style={{ marginTop: 20, height: "auto" }}>{insight.buttonText}</Button>
                    </a>
                </SectionContent>
            </SectionCard>
        </Section>
    );
}

function FavoriteApp({ appUsage }: { appUsage: AppUsageData }) {
    return (
        <>
            {appUsage.mostUsedGame ? (
                <Section>
                    <SectionCard>
                        <div style={{ marginRight: 14, position: 'relative' }}>
                            <img src={favAppGlings} alt="glings" />
                            <Icon
                                style={{ borderRadius: 8, position: 'absolute', left: 18, bottom: 5 }}
                                size={47}
                                src={appUsage.mostUsedGame.icon}
                            />
                        </div>
                        <SectionContent>
                            <SectionContentTitle>{appUsage.kidName}'s favorite game is:</SectionContentTitle>
                            <br />
                            {appUsage.mostUsedGame.title}
                        </SectionContent>
                        <LearnMoreLink href={appUsage.mostUsedGame.url} target="_blank" rel="noopener noreferrer">
                            Learn more
                        </LearnMoreLink>
                    </SectionCard>
                </Section>
            ) : (
                    <Section>
                        <SectionCard>
                            <Icon style={{ marginRight: 16 }} size={60} src={appUsage.mostUsedApp.icon} />
                            <SectionContent>
                                <SectionContentTitle>{appUsage.kidName}'s favorite app is:</SectionContentTitle>
                                <br />
                                {appUsage.mostUsedApp.title}
                            </SectionContent>
                            <LearnMoreLink href={appUsage.mostUsedApp.url} target="_blank" rel="noopener noreferrer">
                                Learn more
                        </LearnMoreLink>
                        </SectionCard>
                    </Section>
                )}
        </>
    );
}

export default function AppUsage() {
    const [appUsage] = useAppUsage();

    return (
        <Container>
            {!appUsage && <Spinner />}
            {appUsage && (
                <>
                    <Title>Hi {appUsage.parentName}!</Title>
                    <Subtitle>
                        Here's your first weekly
                        <br />
                        Glimpse into <SubtitleBlue>{appUsage.kidName}'s world</SubtitleBlue>
                    </Subtitle>
                    <MobileScreenTime appUsage={appUsage} />
                    <TopInterestCategories appUsage={appUsage} />
                    {appUsage.topGamingCategories.category1 && <TopGameCategories appUsage={appUsage} />}
                    {appUsage.topCategories.category1 !== 'Gaming' && (
                        <LearnBasedOnApps
                            appUsage={appUsage}
                            insight={categoriesInsights[appUsage.topCategories.category1]}
                            type="apps"
                            title
                        />
                    )}
                    {appUsage.topCategories.category1 !== 'Gaming' && !appUsage.topGamingCategories.category1 && (
                        <TakeItToYourDailyLife
                            appUsage={appUsage}
                            insight={categoriesInsights[appUsage.topCategories.category1]}
                        />
                    )}
                    {appUsage.topGamingCategories.category1 && (
                        <LearnBasedOnApps
                            appUsage={appUsage}
                            insight={gameCategoriesInsights[appUsage.topGamingCategories.category1]}
                            type="games"
                            title={appUsage.topCategories.category1 === 'Gaming'}
                        />
                    )}
                    {appUsage.topGamingCategories.category1 && (
                        <TakeItToYourDailyLife
                            appUsage={appUsage}
                            insight={gameCategoriesInsights[appUsage.topGamingCategories.category1]}
                        />
                    )}
                    <FavoriteApp appUsage={appUsage} />
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingTop: 24,
                            paddingBottom: 24,
                            marginTop: 30,
                        }}
                    >
                        <Separator style={{ height: 1, width: 274 }} />
                    </div>
                    <img src={logo} style={{ marginBottom: 16 }} alt="Glimpse" />
                    <Text style={{ fontSize: 14, paddingLeft: 10, paddingRight: 10 }}>
                        Hi! We're Glimpse parental app - we're here to help you discover more about your child's digital
                        behaviour, show you how to motivate them, increase their skills and more...
                    </Text>
                </>
            )}
        </Container>
    );
}
