import React from 'react';
import { Text } from './components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import '@brainhubeu/react-carousel/lib/style.css';
import { GlimpseFooter } from './glimpse-footer';
import logoImg from './assets/logo.svg';
import thanksImg from './assets/thanks.svg';

const Container = styled.div`
    display: flex;
    flex-direction: column;    
    height: 100vh;
`;

const Content = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;    
    align-items: center;    
    justify-content: center;
`;

const Title = styled(Text)`
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-left: 32px;
    margin-right: 32px;
`

function Feedback() {

    return <Container>
        <div style={{ alignSelf: "flex-start", paddingLeft: 16, paddingTop: 22 }}><Link to="/"><img src={logoImg} alt="Glimpse" /></Link></div>
        <Content>
            <img style={{ marginBottom: 10 }} src={thanksImg} alt="Thanks!" />
            <Title>Thank you for your Feedback!</Title>
        </Content>
        <GlimpseFooter type="blue" />
    </Container >
}

export default Feedback