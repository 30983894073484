import React from 'react';
import { Title, Space, Text } from '../../components';
import { PageProps } from '.';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;
    padding-left: 60px;
    padding-right: 60px;
    min-width: 262px;
    max-width: 348px;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top: -50px;
    margin-bottom: -74px;
    align-self: center;
`;

const MailBubble = styled.img`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
`;

const VerificationEmail = styled.img`
    position: absolute;
    right: 6px;
    top: 17px;
`;

export default function EmailSent({ navigate, dispatch, state }: PageProps) {
    return (
        <>
            <Title>
                Hi {state.name}, check your inbox to verify your email
            </Title>
            <Space />
            <Container>
                <MailBubble alt="" src={require('./assets/mail_bubble.svg')} />
                <VerificationEmail src={require('./assets/ic_verificaiton_mail.svg')} alt="" />
                <Text style={{ zIndex: 1, color: '#9BA1B8', fontSize: 16 }}>{state.email}</Text>
            </Container>
            <Space />
            <Space />
            <Text
                style={{ fontWeight: 700, color: '#5063FE', zIndex: 2 }}
                onClick={() => navigate("parent-name-and-email")}
            >
                {'I didn’t get the email'}
            </Text>
        </>
    );
}
