import { pdfjs, Document, Page } from 'react-pdf';
import React, { useState } from 'react';
import { SizeMe } from 'react-sizeme';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export function Pdf({ file }: { file: any }) {
    const [numPages, setNumpages] = useState(0)

    return <SizeMe>
        {({ size }) =>
            <Document
                file={file}
                onLoadSuccess={({ numPages }) => setNumpages(numPages)}
                loading="Loading.."
                renderMode="canvas"

            >
                {Array.from(new Array(numPages),
                    (el, index) => (
                        <Page
                            key={`page_${index + 1}`}
                            pageNumber={index + 1}
                            loading=""
                            width={size.width!}
                        />
                    )
                )}
            </Document>
        }
    </SizeMe>
}