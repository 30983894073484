import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Text } from './components';

type Props = { type: 'gray' | 'blue' }

const Logo = styled.img`
    margin-bottom: 17px;
`

const Footer = styled.div<Props>`
    ${({ type }) => type === "blue" ? "background: #4D6AF9;" : ""}
    display:flex;
    box-sizing: border-box;
    width: 100%;
    flex-direction: column;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 34px;    
`

const FooterText = styled(Text) <Props>`
    font-size: 14px;
    ${({ type }) => type === "blue" ? "color: #FFFFFF;" : "color: #868DAE;"}
    text-decoration: none;
    font-weight: 300;
    max-width: 552px;
`;


export function GlimpseFooter({ type }: Props) {
    return <Footer type={type}>
        <Logo alt="glimpse" src={type === "blue" ? require("./assets/footer_logo_white.svg") : require("./assets/footer_logo_gray.svg")} />
        <FooterText type={type} style={{ marginBottom: 40 }}>Hi! We're Glimpse parental app - we're here to help you discover more about your child's digital
                behaviour, show you how to motivate them, increase their skills and more...</FooterText>
        <FooterText type={type}>
            <Link style={{ textDecoration: "none", color: type === "blue" ? "#FFFFFF" : "#868DAE", fontWeight: 500 }} to="/eula">Terms of use</Link> | <Link style={{ textDecoration: "none", color: type === "blue" ? "#FFFFFF" : "#868DAE", fontWeight: 500 }} to="/privacy-policy">Privacy policy</Link>
        </FooterText>
    </Footer >
}
