export type InsightDescription = {
    observationInsightTitle: string;
    observationInsightDescription: string;
    actionableInsight: string;
    buttonText: string;
    buttonLink: string;
};

type InsightMap = {
    [category: string]: InsightDescription;
};

export const gameCategoriesInsights: InsightMap = {
    Words: {
        observationInsightTitle: 'Word Master',
        observationInsightDescription:
            'Asides from the benefit of improving and expanding their knowledge, {kid_name} is improving their cognitive skills and becoming more clever and quick to respond. When a child solves a puzzle, they reason in a logical way, arranging the problem in their mind and recognizing patterns as they reach a desired solution.',
        actionableInsight:
            'Word games are a great opportunity to have fun together. Beyond the classic Scrabble, you have Codenames, Taboo or Quiddler (which is simpler and appropriate for younger kids), word board games that offer a fun experience, on top of vocabulary-building challenges.',
        buttonText: 'Discover word games',
        buttonLink: 'https://www.google.com/search?q=codenames+explained',
    },
    Trivia: {
        observationInsightTitle: 'Trivia Master',
        observationInsightDescription:
            'Asides from the benefit of improving and expanding their knowledge, {kid_name} is improving their cognitive skills and becoming more clever and quick to respond. When a child solves a puzzle, they reason in a logical way, arranging the problem in their mind and recognizing patterns as they reach a desired solution.',
        actionableInsight:
            'Try to ask {kid_name} trivia questions that cover a variety of topics. This is called “cross-training” - when we learn several different topics at the same time, we remember them better.',
        buttonText: 'Try these questions',
        buttonLink: 'https://chartcons.com/100-trivia-questions-kids-answers/',
    },
    Strategy: {
        observationInsightTitle: 'Big Thinker',
        observationInsightDescription:
            '{kid_name} focuses on games that let them practice their planning skills and strategic understanding.',
        actionableInsight:
            'Have fun together.... Strategy board games are a great opportunity to spend some quality time together, have you already tried Settlers of Catan (ages 10+) or Catan Junior (ages 6+)?',
        buttonText: 'Learn a popular game',
        buttonLink: 'https://www.google.com/search?q=settlers+of+catan+explained',
    },
    Sports: {
        observationInsightTitle: 'Coach {kid_name}',
        observationInsightDescription:
            'Sports games are like a workout for kids’ minds! They develop their “brain muscle” by creating strategies for in-game team players and applying management skills.',
        actionableInsight:
            "High levels of competitiveness can drive motivation, but there's always a risk that “fear of failure” will hold a kid back. Playing competitive games and facing failure prepares kids for coping with disappointment in the real world.",
        buttonText: 'How to face failure',
        buttonLink: 'https://www.huffpost.com/entry/teaching-kids-failure-resilience_l_5c882690e4b038892f485ba9',
    },
    Simulation: {
        observationInsightTitle: 'Real Life Wonder',
        observationInsightDescription:
            "{kid_name} puts themselves in activities taken from real life, where they have the opportunity to be strategic, experience planning and learn as the game evolves. These games usually don't have any strictly defined goals and they allow the player to control a character or their environment freely.",
        actionableInsight: 'Your kid has a knack for decision-making, try to nurture this ability.',
        buttonText: 'Do it with a game',
        buttonLink: 'https://www.commonsense.org/education/top-picks/games-for-building-decision-making-skills',
    },
    'Role Playing': {
        observationInsightTitle: 'Adventure Seeker',
        observationInsightDescription:
            '{kid_name} enjoys taking part in games with fictional settings, where they can choose the role they will play. Each role is different and it teaches them to take responsibility for the actions and outcomes that come with it.',
        actionableInsight:
            'Have you considered assigning chores? Giving {kid_name} responsibilities at home will make them feel like a contributing member of the household. Clearly defined tasks and expectations, followed by honest feedback, help raise young kids into independent adults.',
        buttonText: 'Tips on responsibility',
        buttonLink:
            'https://www.parenttoolkit.com/social-and-emotional-development/advice/responsible-decision-making/developing-responsibility-in-kids-ages-8-11',
    },
    Racing: {
        observationInsightTitle: 'Fast Thinker',
        observationInsightDescription:
            'Racing games are a great way to practice fast thinking, fast reaction time and improve skills over time.',
        actionableInsight:
            "Have fun together - there are games that you can play to test and improve {kid_name}'s reflexes",
        buttonText: 'Working on reflexes',
        buttonLink: 'https://www.youtube.com/watch?v=rSBAshAaIbA',
    },
    Puzzle: {
        observationInsightTitle: 'Young Detective',
        observationInsightDescription: '{kid_name} seems to enjoy practicing her problem-solving skills.',
        actionableInsight: 'Have fun together.... Find an Escape Room near you!',
        buttonText: 'Pick one together',
        buttonLink: 'https://www.google.com/search?q=escape+room+near+me',
    },
    Music: {
        observationInsightTitle: 'Music Lover',
        observationInsightDescription:
            'Music games are a great way for {kid_name} to be exposed to different music styles and increase their perception and creativity.',
        actionableInsight:
            'Here’s a challenge for you - open YouTube and send {kid_name} your favorite song. What did they reply? Let us know in our Messenger chat. In the meantime, want to know how music can improve your kid’s life?',
        buttonText: 'Find out here',
        buttonLink: 'https://www.newsweek.com/music-children-mozart-brain-well-being-558783',
    },
    Educational: {
        observationInsightTitle: 'Thirsty for Knowledge',
        observationInsightDescription:
            "Looks like {kid_name} loves to play educational games, which is great because they're good for learning and usually have only appropriate content.",
        actionableInsight:
            'Help {kid_name} discover new educational games that will keep them mind stimulated. Here’s a list of games you can introduce them to and even play together:',
        buttonText: 'Pick a game',
        buttonLink: 'https://afineparent.com/gift-guides/learning-games-for-kids.html ',
    },
    Casual: {
        observationInsightTitle: 'Playing it Cool',
        observationInsightDescription:
            '{kid_name} likes games that start off with quick short levels and then become challenging and more difficult to complete as the game progresses.',
        actionableInsight:
            'If you want to help {kid_name} achieve great things, try to break assignments down into small challenges.',
        buttonText: 'Breaking down tasks',
        buttonLink: 'https://www.youtube.com/watch?v=38fWRlP4ZMU',
    },
    Casino: {
        observationInsightTitle: 'Baby Card Shark',
        observationInsightDescription:
            'Casino games have seen a sharp increase in availability, which may introduce gambling at a far younger age than in previous generations. Games that simulate gambling pose a risk to young people by presenting gambling as attractive and relatively harmless.',
        actionableInsight:
            'Talk to {kid_name} about the differences between skill-based games (like video games and sports), where practice can pay off, versus gambling games, where the outcome is random and chance-based.',
        buttonText: 'How to approach it',
        buttonLink:
            'https://www.cybersafeireland.org/blog/posts/2019/june/social-casino-games-the-convergence-of-gaming-and-gambling/',
    },
    Card: {
        observationInsightTitle: 'Jack of All Trades',
        observationInsightDescription: '{kid_name} seems to enjoy different types of card games',
        actionableInsight:
            'Card games are a great opportunity to have fun together, just take them out of the phone and bring them back to the table. Try some of these recommended games in your next family game night:',
        buttonText: 'Family card games',
        buttonLink: 'https://childhood101.com/family-card-games/',
    },
    Board: {
        observationInsightTitle: 'Monopoly Master',
        observationInsightDescription: '{kid_name} seems to enjoy different types of board games',
        actionableInsight:
            'Board games are a great opportunity to have fun together. From classics like Clue (ages 8+) to the popular Settlers of Catan (ages 10+), here’s a list for your next family game night:',
        buttonText: 'Family board games',
        buttonLink: 'https://www.fractuslearning.com/best-board-games-for-kids/',
    },
    Arcade: {
        observationInsightTitle: 'Not a Quitter',
        observationInsightDescription:
            '{kid_name} likes games that start off with quick short levels and then become challenging and more difficult to complete as the game progresses.',
        actionableInsight:
            'If you want to help {kid_name} achieve great things, try to break assignments down into small challenges.',
        buttonText: 'Breaking down tasks',
        buttonLink: 'https://www.youtube.com/watch?v=38fWRlP4ZMU',
    },
    Adventure: {
        observationInsightTitle: 'Curiosity is Key',
        observationInsightDescription: '{kid_name} enjoys playing games that take them on an adventure.',
        actionableInsight:
            'Curiosity is great! It’s what drives kids (and adults) to explore and learn new things. Here are some tips on how to encourage curiosity in kids',
        buttonText: "Let's get curious",
        buttonLink:
            'https://www.lifehack.org/444453/13-ways-to-encourage-curiosity-in-children-that-most-parents-ignore',
    },
    Action: {
        observationInsightTitle: 'The Flash',
        observationInsightDescription:
            '{kid_name} is drawn to physical challenges and games that improve reflexes, reaction time and hand-eye coordination.',
        actionableInsight:
            'Physical games are a great opportunity to have fun together. Here are some activities you can do together to test, and work on, {kid_name}’s reflexes',
        buttonText: 'Working on reflexes',
        buttonLink: 'https://www.youtube.com/watch?v=rSBAshAaIbA',
    },
};

export const categoriesInsights: InsightMap = {
    Social: {
        observationInsightTitle: 'Social Butterfly',
        observationInsightDescription:
            '{kid_name} is very outgoing, they like spending time in social networking apps and communicating with others.',
        actionableInsight:
            "Kids like to make friends and stay in touch, and being social is a great quality, but they're not always aware of the consequences of their online interactions. Help {kid_name} communicate safely with these tips:",
        buttonText: 'Tips on social media',
        buttonLink: 'https://www.commonsensemedia.org/social-media',
    },
    Entertainment: {
        observationInsightTitle: 'Media Mogul',
        observationInsightDescription:
            'From videos and music to comics and more, {kid_name} loves consuming media, it’s their favorite form of entertainment.',
        actionableInsight:
            'While {kid_name} may love to browse, not all media channels are appropriate for kids. Encourage them to subscribe to relevant kid-friendly channels, instead of roaming around YouTube.',
        buttonText: 'Get to know YouTube',
        buttonLink: 'https://www.commonsensemedia.org/blog/parents-ultimate-guide-to-youtube-kids',
    },
    'Creativity & Curiosity': {
        observationInsightTitle: 'Creative Genius',
        observationInsightDescription:
            'Whether it’s taking photos or browsing through inspirational design content, looks like {kid_name} has a good eye for art.',
        actionableInsight:
            'Encouraging an artistic kid isn’t always easy, but as a parent you should get creative along with them. Try to help {kid_name} find their inner passion and develop it.',
        buttonText: 'How to get creative',
        buttonLink: 'https://edition.cnn.com/2018/02/22/health/kids-create-media-partner/index.html',
    },
    Tools: {
        observationInsightTitle: 'Tech Savvy',
        observationInsightDescription:
            '{kid_name} knows how to utilizes their phone to the fullest. From mastering all phone features to digging into the settings, looks like you have your own live-in tech support.',
        actionableInsight:
            "While it’s great that {kid_name} has a strong grasp of technology, you should make sure that they are aware of the importance of privacy settings and the reasons they're there. Make sure to discuss all possible digital dangers with them.",
        buttonText: 'Cover these basics',
        buttonLink: 'https://www.commonsensemedia.org/privacy-and-internet-safety',
    },
    Education: {
        observationInsightTitle: 'Knowledge Seeker',
        observationInsightDescription:
            "We identified a positive tendency to use technology for learning new things; whether through math apps, how-to videos, or wiki pages, your kid has a thirst for knowledge and they're looking for answers.",
        actionableInsight:
            'Technology is a great tool for kids who are eager to learn and discover new things. You can help {kid_name} make the most of the connected world we live in – here are a few tips to get you started:',
        buttonText: 'Learning with tech',
        buttonLink: 'https://www.commonsensemedia.org/learning-with-technology',
    },
    Mixed: {
        observationInsightTitle: 'The Explorer',
        observationInsightDescription:
            "We can't really put our finger on one specific field of interest, because your kid seems to be into everything! {kid_name} uses a wide range of apps, including apps that aren't usually used by other kids in their age group.",
        actionableInsight:
            'It may be time for you to jump in and get a better picture of what’s going on in your kid’s phone. Here are some tips on setting guidelines, discussing responsible cellphone use and managing the challenges vs. the opportunities they bring:',
        buttonText: 'Setting phone rules',
        buttonLink: 'https://www.commonsensemedia.org/cellphone-parenting',
    },
};
