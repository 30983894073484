import * as jwt from 'jsonwebtoken';

const authToken = document.location.hash.match('token=(.+)');
if (authToken) {
    const decoded = jwt.decode(authToken[1])! as {[key: string]: any};
    if (decoded.resource) {
        localStorage.setItem(`token_${decoded.resource}`, authToken[1]);
    } else {
        localStorage.setItem('token', authToken[1]);
    }
}

declare var FS: any; // fullstory

export function getResourceToken(resource: string) {
    return localStorage.getItem(`token_${resource}`);
}

export function getCurrentUser() {
    const currentToken = localStorage.getItem('token');
    if (!currentToken) {
        return null;
    }
    const user = jwt.decode(currentToken)! as {[key: string]: any};
    console.log(user);

    FS.identify(user['sub']);

    return {
        token: currentToken,
        name: user!['name'] as string,
    };
}
