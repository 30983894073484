import React from 'react';
import './App.css';
import AppUsage from './reports/app-usage';
import Home from './home';
import Onboarding from './onboarding';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Pdf } from './pdf';
import Feedback from './feedback';

const App: React.FC = () => {
    return (
        <Router>
            <Route path="/" exact component={Home} />
            <Route path="/privacy-policy" exact component={() => <Pdf file={require("./assets/privacy-policy.pdf")} />} />
            <Route path="/eula" exact component={() => <Pdf file={require("./assets/eula.pdf")} />} />
            <Route path="/onboarding" exact component={Onboarding} />
            <Route path="/reports/app-usage" exact component={AppUsage} />
            <Route path="/feedback" exact component={Feedback} />
        </Router>
    );
};

export default App;
