import React, { useState, useEffect } from 'react';
import { Title, Form, FormGroup, FormInput, FormLabel, Feedback, Button, Space, Emoji } from '../../components';
import { PageProps } from '.';

const pronounsByGender: { [gender: string]: string } = {
    boy: 'him',
    girl: 'her',
    private: 'them'
};

export default function KidName({ navigate, dispatch, state }: PageProps) {
    const [kidName, setKidName] = useState(() => state.kidName || "");

    useEffect(() => {
        dispatch({ type: "kid-name-posted", kidName: kidName.replace(/^\w/, c => c.toUpperCase()) })
    }, [dispatch, kidName])

    return <>
        <Title>What should we call {pronounsByGender[state.kidGender!!]}? Nicknames work too <Emoji symbol="🐯" /></Title>
        <Form style={{ flex: 1 }} onSubmit={() => navigate("kid-phone-type")}>
            <FormGroup>
                <FormInput
                    value={kidName}
                    minLength={2}
                    pattern='[A-Za-z ]+'
                    onChange={(e: any) => setKidName(e.target.value)}
                    required
                />
                <FormLabel>First Name</FormLabel>
                <Feedback>Enter a first name, anything will do</Feedback>
            </FormGroup>
            <Space />
            <Button disabled={!kidName} type="submit">Next</Button>
        </Form>
    </>
}