import React, { useState, useEffect } from 'react';
import { Title, Button, Space, SingleSelection, SelectionText, Emoji, CenterContainer } from '../../components';
import { PageProps } from '.';



export default function KidGender({ navigate, dispatch, state }: PageProps) {
    const [kidGender, setKidGender] = useState<string | undefined>(() => state.kidGender);

    useEffect(() => {
        dispatch({ type: "kid-gender-selected", kidGender })
    }, [dispatch, kidGender])

    return <>
        <Title>Let’s get started. Is your kid a boy or girl?</Title>
        <CenterContainer>
            <SingleSelection initialValue={kidGender} onSelect={setKidGender} options={{
                "boy": <>
                    <Emoji label="Boy" size={30} symbol="👦" />
                    <SelectionText style={{ fontSize: 16 }}>Boy</SelectionText>
                </>,
                "girl": <>
                    <Emoji label="Girl" size={30} symbol="👧" />
                    <SelectionText>Girl</SelectionText>
                </>,
                "private": <>
                    <Emoji label="Nevermind" size={30} symbol="🐥" />
                    <SelectionText>Nevermind</SelectionText>
                </>
            }} />
        </CenterContainer>
        <Space style={{ height: 42 }} />
        <Button disabled={!kidGender} onClick={() => navigate("kid-age")}>Next</Button>
    </>
}