export const categoryAssets: {[category: string]: {color: string; icon: any}} = {
    Gaming: {
        color: '#34DEE0',
        icon: require('./games.svg'),
    },
    Social: {
        color: '#FFA24D',
        icon: require('./social.svg'),
    },
    'Creativity & Curiosity': {
        color: '#FAD143',
        icon: require('./creativity.svg'),
    },
    Entertainment: {
        color: '#B136EB',
        icon: require('./entertainment.svg'),
    },
    Tools: {
        color: '#7BF086',
        icon: require('./tools.svg'),
    },
    Education: {
        color: '#4AA8FF',
        icon: require('./education.svg'),
    },
    Mixed: {
        color: '#ED54B0',
        icon: require('./mixed.svg'),
    },
};
