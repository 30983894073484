import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Text } from '../../components';

const circlePropertiesBySize = {
    large: {
        top: 30,
        left: 50,
        size: 135,
        fontSize: 16,
    },
    medium: {
        top: 20,
        left: 199,
        size: 104,
        fontSize: 10,
    },
    small: {
        top: 129,
        left: 176,
        size: 84,
        fontSize: 9,
    },
};

const CircleContainer = styled.div<{ size: 'small' | 'medium' | 'large'; color: string }>`
    font-family: museo-sans-rounded;
    font-weight: 700;
    color: #002952;
    position: absolute;
    display: flex;
    border: 5px solid ${({ color }) => color};
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    overflow: hidden !important;
    height: ${({ size }) => circlePropertiesBySize[size].size}px;
    width: ${({ size }) => circlePropertiesBySize[size].size}px;
    top: ${({ size }) => circlePropertiesBySize[size].top}px;
    left: ${({ size }) => circlePropertiesBySize[size].left}px;
    font-size: ${({ size }) => circlePropertiesBySize[size].fontSize}px;
`;

const ShortableText = styled.span<{ width: number }>`
    max-width: ${({ width }) => width}px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export function Circle({
    size,
    color,
    text,
    icon,
}: {
    size: 'small' | 'medium' | 'large';
    color: string;
    text: string;
    icon?: any;
}) {
    return (
        <CircleContainer color={color} size={size}>
            {icon && <Icon size={42} src={icon} />}
            <ShortableText width={circlePropertiesBySize[size].size - 24 - (icon ? 15 : 0)}>{text} </ShortableText>
        </CircleContainer>
    );
}

export const Icon = styled.img<{ size: number }>`
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
`;

export const SectionContentTitle = styled(Text)`
    font-size: 16px;
    font-weight: 700;
    line-height: 128.74%;
    align-self: center;
`;

export const SectionContent = styled(Text)`
    font-size: 16px;
    line-height: 128.74%;
    font-weight: 300;
`;

export const LearnMoreLink = styled.a`
    position: absolute;
    font-family: museo-sans-rounded;
    text-decoration: none;
    right: 30px;
    bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 128.74%;
    color: #4d6af9;
`;

export const Separator = styled.div`
    width: 1px;
    height: 77px;
    background: rgba(196, 196, 196, 0.51);
`;

export const InfoText = styled(Text)`
    font-size: 12px;
    font-weight: 500;
    line-height: 128.74%;
    text-align: center;
    color: #9ba1b8;
    margin-bottom: 2px;
`;

export const TotalUsageStatsNumber = styled(Text)`
    font-size: 16px;
    font-weight: 700;
    line-height: 128.74%;
    text-align: center;
`;
export const TotalUsageStatsMeasure = styled(Text)`
    font-size: 12px;
    font-weight: 500;
    line-height: 128.74%;
    text-align: center;
`;

export const TotalUsageStats = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
    width: 100%;
    height: 100%;

    margin-bottom: 16px;
`;
export const SectionCard = styled.div`
    position: relative;
    background: #ffffff;
    align-items: center;
    box-shadow: 0px 5px 39px rgba(107, 112, 140, 0.15);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    padding: 24px;
`;

export const SectionTitle = styled(Text)`
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    margin-bottom: 8px;
    margin-top: 16px;
`;

export const Subtitle = styled(Text)`
    font-size: 22px;
    line-height: 114.5%;
    text-align: center;
    margin-bottom: 26px;
    font-weight: 700;
`;

export const SubtitleBlue = styled.span`
    color: #4d6af9;
`;

export const Title = styled(Text)`
    font-size: 32px;
    line-height: 108.47%;
    margin-bottom: 9px;
    font-weight: 700;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    margin: 18px;
`;

export const spin = keyframes`
    to {
                transform: rotate(360deg);
        }
    `;

export const Spinner = styled.div`
    height: 5vh;
    width: 5vh;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    border: 2px solid rgb(0, 174, 239);
    border-bottom-color: transparent;
    background: transparent !important;
    animation: ${spin} 0.75s 0s linear infinite;
`;