import React, { useReducer, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Line } from 'rc-progress';
import pages, { State } from './pages';
import { getCurrentUser } from '../util/auth';
import { useMediaQuery } from 'react-responsive';
import logoImg from '../assets/logo.svg';
import { GlimpseFooter } from '../glimpse-footer';
import { Space } from '../components';
import { Link } from 'react-router-dom';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 32px;
    padding-top: 24px;    
    align-items: center;
    min-height: 100vh;
    box-sizing: border-box;
`;

const DesktopOuterFrame = styled.div`
    display: flex;
    flex-direction: column;
    padding: 27px;
    align-items: center;
    background: #FCFCFF;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
`;

const DesktopInnerFrame = styled.div`
    max-width: 809px;
    max-height: 669px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 3.97561px 7.58px rgba(155, 161, 184, 0.18);
    border-radius: 11.9268px;
    padding: 46px;
    padding-left: 85px
    padding-right: 85px
    padding-bottom: 60px;
    
    flex: 1;
    display: flex;    
    flex-direction: column;
    text-align: center;
`;

function DesktopContainer({ children }: { children: any }) {
    return <DesktopOuterFrame>
        <div style={{ alignSelf: "flex-start" }}><Link to="/"><img src={logoImg} alt="Glimpse" /></Link></div>
        <DesktopInnerFrame>{children}</DesktopInnerFrame>
        <Space />
        <GlimpseFooter type="gray" />
    </DesktopOuterFrame>
}

function reducer(state: State, action: { type: string }) {
    const { type, ...payload } = action;

    switch (type) {
        case 'reload-state':
        case 'parent-name-and-email-posted':
        case 'kid-gender-selected':
        case 'kid-age-selected':
        case 'kid-name-posted':
        case 'kid-phone-type-selected':
            const newState = { ...state, ...payload };
            localStorage.setItem('onboarding-state', JSON.stringify(newState));
            return newState;
        default:
            throw new Error();
    }
}

export default function Onboarding() {
    const params = new URLSearchParams(window.location.search);
    const user = getCurrentUser();
    const [state, dispatch] = useReducer(reducer, {
        name: user ? user.name : undefined,
        token: user ? user.token : undefined,
    });

    const [page, setPage] = useState(() => params.get('page') || 'parent-name-and-email')

    useEffect(() => {
        const pageFromUrl = params.get('page');
        if (pageFromUrl !== page) {
            window.history.pushState(page, 'Glimpse', `/onboarding?page=${page}`)
        }
    }, [page, params])

    useEffect(() => {
        window.onpopstate = () => { if (params.get('page') !== page) setPage(params.get('page') || 'parent-name-and-email') }
        return () => { window.onpopstate = null }
    }, [setPage, page, params])

    const Page = pages[page];

    useEffect(() => {
        const stateString = localStorage.getItem('onboarding-state')
        if (stateString) {
            const state = JSON.parse(stateString);
            dispatch({ ...state, type: "reload-state" });
        }
    }, [dispatch]);

    const progress = (100 * Object.keys(pages).indexOf(page)) / Object.keys(pages).length;

    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1024px)' })
    const ContainerToRender = isBigScreen ? DesktopContainer : Container;

    return (
        <ContainerToRender>
            <Line
                percent={progress}
                strokeColor="#4150FF"
                trailColor="#E3E6F2"
                style={{ height: 7, width: '100%', borderRadius: 10 }}
            />
            <Page navigate={setPage} dispatch={dispatch} state={state} />
        </ContainerToRender>
    );
}
