import React from 'react';
import './double-arrow-button.css';

export default function () {
    return <div className="center-con">
        <div className="round">
            <div id="cta">
                <span className="arrow primera next "></span>
                <span className="arrow segunda next "></span>
            </div>
        </div>
    </div>

}