import React, { useState, useEffect } from 'react';
import { Title, Button, Space, SingleSelection, SelectionText, Emoji, Feedback, CenterContainer } from '../../components';
import { PageProps } from '.';

export default function KidPhoneType({ navigate, dispatch, state }: PageProps) {
    const [kidPhoneType, setKidPhoneType] = useState<string | undefined>(() => state.kidPhoneType);

    useEffect(() => {
        dispatch({ type: "kid-phone-type-selected", kidPhoneType });
    }, [dispatch, kidPhoneType]);

    return <>
        <Title>What type of phone {state.kidName} have?</Title>
        <CenterContainer>
            <SingleSelection initialValue={kidPhoneType} onSelect={setKidPhoneType} options={{
                "android": <>
                    <Emoji label="Android" size={30} symbol="🤖" />
                    <SelectionText>Android</SelectionText>
                </>,
                "ios": <>
                    <Emoji label="iPhone" size={30} symbol="🍎" />
                    <SelectionText>iPhone</SelectionText>
                </>
            }} />
            {kidPhoneType === "ios" && <div style={{ position: "relative", width: 180 }}>
                <Feedback style={{ position: "absolute", display: "inline-block", width: 180, left: 22, marginTop: 10, marginLeft: 0 }}>
                    Sorry <Emoji symbol="😕" /> we don’t support iOS devices yet
            </Feedback>
            </div>}
        </CenterContainer>
        <Space style={{ height: 42 }} />
        <Button disabled={!kidPhoneType || kidPhoneType === "ios"} onClick={() => navigate("go-to-playstore")}>Next</Button>
    </>
}