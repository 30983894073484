import {useState, useEffect} from 'react';
import * as R from 'ramda';
import {AppUsageData, UsageStats} from './types';
import {getCurrentUser, getResourceToken} from '../../util/auth';

declare global {
    interface Window {
        extAsyncInit: any;
    }
}

export function useAppUsage() {
    const [appUsage, setAppUsage] = useState<AppUsageData | undefined>(undefined);

    useEffect(() => {
        if (appUsage) return;
        const params = new URLSearchParams(window.location.search);
        const timestamp = params.get('timestamp') || new Date().getTime();

        const user = getCurrentUser();

        fetch(`https://us-central1-carent-bd07d.cloudfunctions.net/appUsage?timestamp=${timestamp}`, {
            headers: {
                Authorization: 'Bearer ' + (getResourceToken(`report-app-usage-${timestamp}`) || user!.token),
            },
        })
            .then(res => res.json())
            .then(appUsageResponse => {
                const usageStats = R.pipe(
                    R.values,
                    R.sortWith([R.descend(R.prop('totalTimeInForeground') as any)]) as any
                )(appUsageResponse.usageStats) as any[];

                const totalUsageTime =
                    R.pipe(
                        R.map((x: UsageStats) => x.totalTimeInForeground),
                        R.sum
                    )(usageStats) /
                    1000 /
                    60 /
                    60;
                const [mostUsedApp] = R.filter((x: UsageStats) => !!x.categoryId)(usageStats);
                const [mostUsedGame] = R.filter((x: UsageStats) => !!x.categoryId && x.categoryId.startsWith('GAME'))(
                    usageStats
                );

                const [category1, category2, category3] = top3Stats(
                    usageStats,
                    (x: UsageStats) => !!x.categoryId,
                    (x: UsageStats) => appsCategoryMap[x.categoryId] || 'Mixed'
                );
                const [gameCategory1, gameCategory2, gameCategory3] = top3Stats(
                    usageStats,
                    (x: UsageStats) => !!x.categoryId && x.categoryId.startsWith('GAME'),
                    (x: UsageStats) => x.category
                );

                setAppUsage({
                    periodInDays: appUsageResponse.periodInDays,
                    usageStats,
                    totalUsageTime,
                    mostUsedApp,
                    mostUsedGame,
                    parentName: appUsageResponse.parentName,
                    kidName: appUsageResponse.kidName,
                    kidGender: appUsageResponse.kidGender,
                    kidAge: appUsageResponse.kidAge,
                    topCategories: {category1, category2, category3},
                    topGamingCategories: {category1: gameCategory1, category2: gameCategory2, category3: gameCategory3},
                });
            });
    }, [setAppUsage, appUsage]);

    return [appUsage];
}

function top3Stats(
    usageStats: UsageStats[],
    filter: (usageStats: UsageStats) => boolean,
    groupBy: (usageStats: UsageStats) => string
) {
    const filtered = R.filter(filter)(usageStats);
    const grouped = R.groupBy(groupBy)(filtered);
    const groupedUsages = R.mapObjIndexed((x: UsageStats[]) => x.map(y => y.totalTimeInForeground))(grouped);
    const groupedTotalUsage = R.mapObjIndexed(R.sum)(groupedUsages);
    const groupedUsagePair = R.toPairs(groupedTotalUsage);
    const sorted = R.sortWith([R.descend(R.nth(1))])(groupedUsagePair);
    const [category1, category2, category3] = R.pipe(
        R.map(R.nth(0)),
        R.take(3)
    )(sorted) as string[];
    return [category1, category2, category3];
}

type Category = 'Gaming' | 'Social' | 'Entertainment' | 'Creativity & Curiosity' | 'Tools' | 'Education' | 'Mixed';

const appsCategoryMap: {[categoryId: string]: Category} = {
    GAME_WORD: 'Gaming',
    GAME_TRIVIA: 'Gaming',
    GAME_STRATEGY: 'Gaming',
    GAME_SPORTS: 'Gaming',
    GAME_SIMULATION: 'Gaming',
    GAME_ROLE_PLAYING: 'Gaming',
    GAME_RACING: 'Gaming',
    GAME_PUZZLE: 'Gaming',
    GAME_MUSIC: 'Gaming',
    GAME_EDUCATIONAL: 'Gaming',
    GAME_CASUAL: 'Gaming',
    GAME_CASINO: 'Gaming',
    GAME_CARD: 'Gaming',
    GAME_BOARD: 'Gaming',
    GAME_ARCADE: 'Gaming',
    GAME_ADVENTURE: 'Gaming',
    GAME_ACTION: 'Gaming',
    SOCIAL: 'Social',
    COMMUNICATION: 'Social',
    VIDEO_PLAYERS_AND_EDITORS: 'Entertainment',
    MUSIC_AND_AUDIO: 'Entertainment',
    COMICS: 'Entertainment',
    SPORTS: 'Entertainment',
    PHOTOGRAPHY: 'Creativity & Curiosity',
    ENTERTAINMENT: 'Creativity & Curiosity',
    ART_AND_DESIGN: 'Creativity & Curiosity',
    LIFESTYLE: 'Creativity & Curiosity',
    TOOLS: 'Tools',
    PERSONALIZATION: 'Tools',
    PRODUCTIVITY: 'Tools',
    TRAVEL_AND_LOCAL: 'Tools',
    MAPS_AND_NAVIGATION: 'Tools',
    EDUCATION: 'Education',
    BOOKS_AND_REFERENCE: 'Education',
    NEWS_AND_MAGAZINES: 'Education',
};
