import React, { useState, useEffect } from 'react';
import { Title, Button, Space, Text, CenterContainer } from '../../components';
import { Range } from 'react-range';
import { PageProps } from '.';
import styled, { keyframes } from 'styled-components';

const Track = styled.div`
    height: 5px;
    width: 100%;
    background-color: #E3E6F2;
    border-radius: 10px;
`

const Thumb = styled.div`
    height: 35px;
    width: 35px;
    background-color: #FFFFFF;
    border: 2px solid #E3E6F2;
    box-sizing: border-box;
    box-shadow: 0px 2px 14px rgba(107, 112, 140, 0.1);
    border-radius: 18px;
`

const Anchor = styled.div`
    position: relative;
    bottom: 58px;
    left: -6px;
    padding: 10px;
    background-image: url(${require("./assets/age_selector.svg")});
    width: 44px;
    height: 53px;
    box-sizing: border-box;
`

const fadeout = keyframes`
    0%   { opacity: 1 }
    85%  { opacity: 1 }
    100%  { opacity: 0 }
`;

const AgeLimit = styled(Text) <{ type: "min" | "max" }>`
    position: absolute;
    display: block;
    ${({ type }) => type === "max" ? "right: 15px;" : "left: -9px;"}
    top: 34px;
    background-image: url(${({ type }) => type === "max" ? require("./assets/tooltip-right.svg") : require("./assets/tooltip-left.svg")});
    border-radius: 10px;
    font-size: 14px;
    font-weight: 500;
    color: #6B708C;
    align-self: flex-end;
    width: 153px;
    padding: 14px;
    text-align: left;
    padding-top: 26px;
    margin-right: -23px;
    pointer-events: none;
    opacity: 0;
    animation: ${fadeout} 2s ease-in-out;
`;

const slidingUnits = 40;

export default function KidAge({ navigate, dispatch, state }: PageProps) {
    const [kidAge, setKidAge] = useState(() => state.kidAge || 10);
    const [slidingNumber, setSlidingNumber] = useState(kidAge * slidingUnits);

    useEffect(() => {
        dispatch({ type: "kid-age-selected", kidAge })
    }, [dispatch, kidAge])

    return <>
        <Title>How old are they?</Title>
        <Title style={{ fontSize: 18, fontWeight: 500, marginBottom: 110, marginLeft: 20, marginRight: 20 }}>This will help us curate content for your kid’s age group</Title>
        <CenterContainer style={{ width: "90%" }}>
            <Range step={1} min={8 * slidingUnits} max={14 * slidingUnits} values={[slidingNumber]}
                onChange={values => {
                    setSlidingNumber(values[0])
                    setKidAge(Math.round(values[0] / slidingUnits))
                }}
                renderTrack={({ props, children }) => (
                    <Track {...props}>{children}</Track>
                )}
                renderThumb={({ props }) => (
                    <Thumb {...props}>
                        <Anchor>
                            <Text style={{ fontSize: 16, color: "#fff", fontWeight: 700 }}>{kidAge}</Text>
                        </Anchor>
                        {(kidAge === 8 || kidAge === 14) && <AgeLimit type={kidAge === 8 ? "min" : "max"}>Glimpse is for kids between 8-14 years old</AgeLimit>}
                    </Thumb>
                )}
            />
        </CenterContainer>

        <Space style={{ height: 58 }} />
        <Button disabled={!kidAge} onClick={() => navigate("kid-name")}>Next</Button>
    </>
}