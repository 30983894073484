export default {
    8: '1h 2m',
    9: '2h 18m',
    10: '3h 35m',
    11: '3h 49m',
    12: '4h 4m',
    13: '4h 18m',
    14: '4h 33m',
    15: '4h 48m',
};
