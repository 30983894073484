import React from 'react';
import { Button, Text } from './components';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import DoubleArrowButton from './double-arrow-button'
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { GlimpseFooter } from './glimpse-footer';
import { useMediaQuery } from 'react-responsive';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Bar = styled.div`
    position: sticky;
    top: 0;
    background: white;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 16px;
    z-index: 100;
    box-shadow: 0px 2px 24px rgba(0, 41, 82, 0.15);
`

const Title = styled(Text)`
    display: inline-block;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-left: 32px;
    margin-right: 32px;
`
const MainTitle = styled(Title)`
    color: #FFFFFF;    
    text-shadow: 0px 4.23173px 31.58px rgba(0, 0, 0, 0.25);
    max-width: 413px;

    @media (min-device-width: 1024px){
        font-size: 65px;
        margin-left: 110px;
        text-align: left;
    }
`

const SecondaryTitle = styled(Title)`
    margin-bottom: 16px;
`;

const FeatureTitle = styled(SecondaryTitle)`
    // background: red;//TODO

    @media (min-device-width: 1024px){
        max-width: 300px;

        text-align: left;
    }
`

const CoverImage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 478px;
    background-image: url(${require('./assets/cover_image.png')});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 38% 63%;
    box-shadow: inset 0 248px 374px -248px rgba(38, 19, 23, 1);
    padding-top: 64px;

    @media (min-device-width: 1024px){
        padding-top: 164px;
        margin-bottom: 80px;
    }
`

const CenterContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 62px;

    // background: red;//TODO
`

const FeatureImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
    // background: magenta;//TODO
    @media (min-device-width: 1024px){
        max-width: 360px;
    }
`

const TestemonialsContainer = styled.div`
    width: 100%;
    padding-right: 0px;
    box-sizing: border-box;

    @media (min-device-width: 1024px){
        display: flex;
        flex-direction: row;
        justify-content: center;

        margin-bottom: 80px;
    }
`

const TestemonialConatiner = styled.div`
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 30px;
    padding-right: 8px;
`

const Testemonial = styled.div`
    display: flex;
    flex-direction: column;
    justify-conetn: center;

    width: 278px;
    padding: 20px;
    padding-left: 32px;
    padding-right: 32px;

    background: #FFFFFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 30px rgba(107, 112, 140, 0.13);
    border-radius: 10px;

    text-align: center;
    overflow: visible;
`

const TestemonialPhoto = styled.img`
    display: block;
`;

const TestemonialPersonName = styled(Text)`
    font-size: 16px;
    font-weight: 700;
    line-height: 139.7%;
    text-align: center;

    margin-bottom: 20px;
`;

const TestemonialContent = styled(Text)`
    font-size: 16px;
    font-weight: 300;
    line-height: 139.7%;
    text-align: center;
`;

const Feature = styled.div<{ direction: "ltr" | "rtl" }>`
    @media (min-device-width: 1024px){
        width: 900px;
        direction: ${({ direction }) => direction};
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
`;

const TryNow = styled.div`
    display: flex;
    width: 90%;
    box-sizing: border-box;
    padding: 34px;

    flex-direction: column;
    margin-left: 18px;
    margin-right: 18px;
    margin-bottom: 27px;
    background: #F4F6F7;
    border-radius: 5px;
    justify-content: center;

    max-width: 1000px;

    @media (min-device-width: 1024px){
        margin-bottom: 150px;
        padding: 60px;
    }
`

const TryNowTitle = styled(Title)`
    font-size: 24px;
    margin-bottom: 22px;

    @media (min-device-width: 1024px){
        font-size: 44px;
        margin-bottom: 32px;
    }    
`;

const TryNowFeatures = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 28px;

    max-width:520px;
    width: 100%;
    align-self: center;

    @media (min-device-width: 1024px){
        margin-bottom: 70px;
    }    
`;
const TryNowFeature = styled.span`
    display: flex;
    align-items: center;
`;

const TryNowFeatureImg = styled.img`
    @media (min-device-width: 1024px){
        height: 24px;
        margin-right: 2px;
    }
`;

const TryNowFeatureText = styled(Text)`
    font-size: 14px;
    font-weight: 500;

    @media (min-device-width: 1024px){
        font-size: 24px;
    }
`

function Home() {

    return <Container>
        <Bar><img alt="glimpse" src={require("./assets/logo.svg")} /><Link to="/onboarding"><Button>Get Started</Button></Link></Bar>
        <CoverImage>
            <MainTitle>Get a glimpse into your kid's world</MainTitle>
            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "flex-end", paddingBottom: 80, height: 80 }}><DoubleArrowButton /></div>
        </CoverImage>
        <Feature direction="rtl">
            <FeatureTitle style={{ marginTop: 62 }}>Get weekly updates about your kid’s phone usage</FeatureTitle>
            <CenterContainer><FeatureImage style={{ width: "90%" }} src={require("./assets/usage.png")} /></CenterContainer>
        </Feature>
        <Feature direction="ltr">
            <FeatureTitle>Find out more about your kid’s interests</FeatureTitle>
            <CenterContainer style={{ paddingBottom: 42 }}><FeatureImage src={require("./assets/interests.png")} /></CenterContainer>
        </Feature>
        <Feature direction="rtl">
            <FeatureTitle style={{ marginBottom: 18 }}>Learn how to use it in your daily life</FeatureTitle>
            <CenterContainer><FeatureImage src={require("./assets/dailylife.png")} /></CenterContainer>
        </Feature>

        <SecondaryTitle>What parents are saying about us</SecondaryTitle>

        <Testemonials />

        <TryNow>
            <TryNowTitle>Try Glimpse now</TryNowTitle>
            <TryNowFeatures>
                <TryNowFeature><TryNowFeatureImg src={require("./assets/check.svg")} /><TryNowFeatureText>Free</TryNowFeatureText></TryNowFeature>
                <TryNowFeature><TryNowFeatureImg src={require("./assets/check.svg")} /><TryNowFeatureText>Easy set up</TryNowFeatureText></TryNowFeature>
                <TryNowFeature><TryNowFeatureImg src={require("./assets/check.svg")} /><TryNowFeatureText>Private & secure</TryNowFeatureText></TryNowFeature>
            </TryNowFeatures>
            <Link style={{ alignSelf: "center" }} to="/onboarding"><Button>Get Started</Button></Link>
        </TryNow>

        <GlimpseFooter type="blue" />
    </Container >
}

const Testemonials = () => {
    const isBigScreen = useMediaQuery({ query: '(min-device-width: 1024px)' })
    const Container = isBigScreen ? TestemonialsContainer : ({ children }: { children: any }) => <TestemonialsContainer>
        <Carousel
            clickToChange
            slidesPerPage={2}
            itemWidth={278}
            offset={16}

        >
            {children}
        </Carousel>
    </TestemonialsContainer>;

    return <Container>
        <TestemonialConatiner>
            <Testemonial>
                <TestemonialPhoto src={require("./assets/testemonial-merav.svg")} />
                <TestemonialPersonName>Merav Oren Moskovich</TestemonialPersonName>
                <TestemonialContent>“Between my work, his sports and extra curriculars, there isn’t a lot of “us” time left. Now I have an inside look that makes our time together more meaningful”</TestemonialContent>
            </Testemonial>
        </TestemonialConatiner>
        <TestemonialConatiner>
            <Testemonial>
                <TestemonialPhoto src={require("./assets/testemonial-daniel.svg")} />
                <TestemonialPersonName>Daniel Ravner</TestemonialPersonName>
                <TestemonialContent>“I wanted to stay close to my daughter without invading her privacy. I used to review her history but that strained our relationship. This app gets us talking”</TestemonialContent>
            </Testemonial>
        </TestemonialConatiner>
        <TestemonialConatiner>
            <Testemonial>
                <TestemonialPhoto src={require("./assets/testemonial-danny.svg")} />
                <TestemonialPersonName>Danny Boleslavsky</TestemonialPersonName>
                <TestemonialContent>“I had no idea she enjoys strategy games so much. I remember I was the same when I was a kid. Now we have family game nigh and play strategy board games.”</TestemonialContent>
            </Testemonial>
        </TestemonialConatiner>
    </Container>
}

export default Home